/* UserBookPage.css */
.date-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  
  .date-scroll-container button {
    flex: 0 0 auto;
  }

  .modal-header-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 1rem); /* 留出一些空间以避免模态框紧贴屏幕边缘 */
  }
  
  .modal-header-custom .modal-title {
    flex-grow: 1;
    text-align: center;
  }
  
  .modal-header-custom .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

 
/* 应用自定义样式到模态框的dialog元素上 */
.modal.fade.show .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 1rem); /* 根据需要调整 */
  margin: 0 auto; /* 水平居中 */
}