.scrollable-timeslots {
    max-height: 60vh;
    overflow-y: auto; /* 启用垂直滚动条 */
  }
 
  .timeslot-item {
    width: 100%; /* 或者设置一个具体的像素值，比如300px */
    /* 如果你想要更宽的按钮，并且不希望它们随屏幕大小变化，可以使用像素值 */
  }
 
  .timeslot-item button {
    width: 100%; /* 这实际上是多余的，因为btn-block类已经设置了宽度为100% */
    height: 50px;
  }